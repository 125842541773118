import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'
import 'moment-timezone'

import { PurchaseOrderTypeEnum } from '../../../shared/enums.ts'

import { Table, Select, Button, Space, notification, Tooltip, Card, Collapse, Row, Col, Typography, List, Modal } from 'antd'
// import Highlighter from 'react-highlight-words';
import { PlusOutlined, EditOutlined, CheckOutlined, CloseOutlined, RollbackOutlined, CopyOutlined, QuestionCircleOutlined, DoubleLeftOutlined, RetweetOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'

import * as storeActions from './store/actions/progressives'
import { downloadFromS3 } from '../../../store/actions/commonAction'

import { startLoading, endLoading } from '../../../shared/utility'
import TableFilters from '../../common/components/TableFilters'
import DrawerEditProgressive from './components/DrawerEditProgressive'
import DrawerViewProgressive from './components/DrawerViewProgressive'
import DrawerImportProgressives from './components/DrawerImportProgressives'

import Loader from '../../common/PageElements/Loader'
import DropdownMenu from '../../common/components/DropdownMenu'
import { updateObject } from '../../../shared/utility'

class ProgressivesList extends Component {
   state = {
      pageLoader: true,
      tableLoader: false,
      importLoader: false,
      loadersCancel: [],
      loadersRestore: [],
      loadersConfirm: [],
      loadersDownload: [],
      loadersUpload: [],
      loadersDuplicate: [],
      loadersExportDetails: [],
      itemList: [],
      fullListIds: [],
      complements: {},
      componentLanguage: this.props.authState.defaultLanguage,
      paginationParams: {
         current_page: 1,
         per_page: 50,
         total: 0,
      },
      sortedInfo: {},
      selectedRowKeys: [],
      multipleActionLoader: false,
      multipleActionList: [
         {
            action: 'confirm',
            name: 'Conferma progressivi selezionati',
            needconfirmation: 'yes',
         },
         {
            action: 'cancel',
            name: 'Annulla progressivi selezionati',
            needconfirmation: 'yes',
         },
         {
            action: 'duplicate',
            name: 'Duplica progressivi selezionati',
            needconfirmation: 'yes',
         },
         {
            action: 'merge',
            name: 'Unisci progressivi selezionati',
            needconfirmation: 'yes',
         },
         {
            action: 'restore',
            name: 'Ripristina progressivi selezionati',
            needconfirmation: 'yes',
         },
      ],
      multipleExportLoader: false,
      multipleExportList: [
         {
            action: 'export_xlsx',
            name: 'Esporta selezionati in Excel'
         },
         {
            action: 'export_selected_details_xlsx',
            name: 'Esporta dettagli aggiornati'
         },
      ],
      feedback: {},
      searchOrderInput: null,

      filters: [],
      // filtersValues: {},
      appliedFilters: [],

      showModalNote: false,
      modalNoteItem: null,
   };

   getList = (paginationParams, filters) => {
      // asyncronous calls
      console.log(filters);
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(paginationParams, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  paginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },

                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,

                  tableLoader: false,
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               const complements = {
                  vendorCodesList: response.vendorCodesList,
                  typesList: response.typesList,
                  countriesList: response.countriesList,
                  groupsList: response.groupsList,
                  categoriesList: response.categoriesList,
                  quartersList: response.quartersList,
                  brandsList: response.brandsList,
                  warehousesList: response.warehousesList,
                  suppliersList: response.suppliersList,
                  customersList: response.customersList,
               }
               this.setState({
                  complementsResponse: response,
                  complements: complements
               });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         this.getList(this.state.paginationParams, this.state.filters),
         this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys: selectedRowKeys });
   };
   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         case 'export_xlsx':
            this.exportExcelHandler()
            break
         case 'export_selected_details_xlsx':
            this.exportDetailsHandler(this.state.selectedRowKeys, true)
            break
         case 'confirm':
            this.confirmItemsHandler(this.state.selectedRowKeys);
            break
         case 'cancel':
            this.cancelItemsHandler(this.state.selectedRowKeys);
            break
         case 'restore':
            this.restoreItemsHandler(this.state.selectedRowKeys);
            break
         case 'merge':
            if (this.state.selectedRowKeys.length < 2) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.selectAtLeast2Items'),
               })
            } else {
               this.mergeItemsHandler(this.state.selectedRowKeys);
            }
            break
         case 'duplicate':
            this.duplicateItemsHandler(this.state.selectedRowKeys);
            break
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
            break
      }
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
         order_column: !_.isNil(sorter.column) && !_.isNil(sorter.column.key) ? sorter.column.key : null,
         order_direction: !_.isNil(sorter.order) ? sorter.order : null,
      }
      this.setState({ sortedInfo: sorter });
      this.getList(paginationParams, this.state.filtersValues);
   };

   submitFilters = (values) => {
      Object.keys(values).forEach(function (key) {
         if (_.isArray(values[key])) {
            _.forEach(values[key], function (value, key, array) {
               if (Moment.isMoment(value)) {
                  array[key] = Moment(value).format('YYYY-MM-DD');
               }
            })
         } else if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values, selectedRowKeys: [], sortedInfo: {} })
      this.getList(updateObject(this.state.paginationParams, { current_page: 1 }), values);
   }
   resetFilters = () => {
      this.setState({ filtersValues: {}, selectedRowKeys: [], sortedInfo: {} })
      this.getList(updateObject(this.state.paginationParams, { current_page: 1 }), {});
   }

   editItemHandler = item => {
      this.setState({ createDrawerItem: item, showCreateDrawer: true })
   }
   hideCreateDrawer = () => {
      this.setState({ showCreateDrawer: false })
      // this.setState({ createDrawerItem: null, })
   }
   handleCreateOrUpdateProgressive = (data) => {
      console.log('Progressivo creato/aggiornato correttamente')
      this.setState({ showCreateDrawer: false })
      Promise.all([
         this.getList(this.state.paginationParams, this.state.filtersValues),
         // this.getComplements()
      ]).then()
   }
   updateList = () => {
      this.getList(this.state.paginationParams, this.state.filtersValues)
   }

   showViewDrawer = item => {
      this.setState({ viewDrawerItem: item, showViewDrawer: true })
   }
   hideViewDrawer = () => {
      this.setState({ showViewDrawer: false })
   }

   showImportDrawer = (show) => {
      this.setState({ showImportDrawer: show })
   }

   cancelItemsHandler = (itemIds) => {
      this.setState({ loadersCancel: startLoading(this.state.loadersCancel, itemIds), multipleActionLoader: true })
      return storeActions.cancelItems(itemIds)
         .then(response => {
            console.log(response);
            this.setState({ loadersCancel: endLoading(this.state.loadersCancel, itemIds), multipleActionLoader: false })
            try {
               const newSelectedRowKeys = this.state.selectedRowKeys.filter(x => !itemIds.includes(x))
               this.setState({ selectedRowKeys: newSelectedRowKeys })

               //TODO: aggiornare solo la riga modificata
               this.getList(this.state.paginationParams, this.state.filtersValues)
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.cancelOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  duration: 0
               })
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersCancel: endLoading(this.state.loadersCancel, itemIds), multipleActionLoader: false })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   cancelItemHandler = (itemId) => {
      this.cancelItemsHandler([itemId])
   }
   restoreItemsHandler = (itemIds) => {
      this.setState({ loadersRestore: startLoading(this.state.loadersRestore, itemIds), multipleActionLoader: true })
      return storeActions.restoreItems(itemIds)
         .then(response => {
            console.log(response);
            this.setState({ loadersRestore: endLoading(this.state.loadersRestore, itemIds), multipleActionLoader: false })
            try {
               const newSelectedRowKeys = this.state.selectedRowKeys.filter(x => !itemIds.includes(x))
               this.setState({ selectedRowKeys: newSelectedRowKeys })

               //TODO: aggiornare solo la riga modificata
               this.getList(this.state.paginationParams, this.state.filtersValues)
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.restoreOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  duration: 0
               })
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersRestore: endLoading(this.state.loadersRestore, itemIds), multipleActionLoader: false })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   restoreItemHandler = (itemId) => {
      this.restoreItemsHandler([itemId])
   }

   confirmItemsHandler = (itemIds) => {
      this.setState({ loadersConfirm: startLoading(this.state.loadersConfirm, itemIds), multipleActionLoader: true })
      return storeActions.confirmItems(itemIds)
         .then(response => {
            console.log(response);
            this.setState({ loadersConfirm: endLoading(this.state.loadersConfirm, itemIds), multipleActionLoader: false })
            try {
               const newSelectedRowKeys = this.state.selectedRowKeys.filter(x => !itemIds.includes(x))
               this.setState({ selectedRowKeys: newSelectedRowKeys })

               //TODO: aggiornare solo la riga modificata
               this.getList(this.state.paginationParams, this.state.filtersValues)
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.confirmOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  duration: 0
               })
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersConfirm: endLoading(this.state.loadersConfirm, itemIds), multipleActionLoader: false })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   confirmItemHandler = (itemId) => {
      this.confirmItemsHandler([itemId])
   }

   duplicateItemsHandler = async (itemIds, onlyMissing) => {
      this.setState({ loadersDuplicate: startLoading(this.state.loadersDuplicate, itemIds) })
      try {
         const response = await storeActions.duplicateItemsAndSearch(itemIds, onlyMissing)
         console.log(response)
         this.setState({ loadersDuplicate: endLoading(this.state.loadersDuplicate, itemIds) })
         try {
            this.getList(this.state.paginationParams, this.state.filtersValues)
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.operationOk'),
            })
         } catch (e) {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
         }
      } catch (e_1) {
         notification.error({
            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
            description: e_1.error ? (
               <List
                  size="small"
                  split={false}
                  dataSource={e_1.error}
                  renderItem={item_1 => <List.Item>{item_1}</List.Item>} />
            ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            duration: 0
         })
         this.setState({ loadersDuplicate: endLoading(this.state.loadersDuplicate, itemIds) })
         if (this.props.commonState.debug) console.log(e_1)
      }
   }

   mergeItemsHandler = (itemIds) => {
      this.setState({ loadersDelete: startLoading(this.state.loadersDelete, itemIds), multipleActionLoader: true })
      return storeActions.mergeItems(itemIds, false)
         .then(response => {
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.operationOk'),
               duration: 0
            })
            Promise.all([
               this.getList(this.state.paginationParams, this.state.filtersValues),
            ]).then(() => {
               this.setState({ loadersDelete: endLoading(this.state.loadersDelete, itemIds), multipleActionLoader: false })
            });
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersDelete: endLoading(this.state.loadersDelete, itemIds), multipleActionLoader: false })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   sendToAmazonHandler = (itemId) => {
      this.setState({ loadersUpload: startLoading(this.state.loadersUpload, itemId) })
      return storeActions.sendToAmazon(itemId)
         .then(response => {
            console.log(response);
            this.setState({ loadersUpload: endLoading(this.state.loadersUpload, itemId) })
            try {
               this.getList(this.state.paginationParams, this.state.filtersValues)
               if (!_.isNil(response.human) && response.status === 'ok') {
                  notification.success({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: (
                        <List
                           size="small"
                           split={false}
                           dataSource={response.human}
                           renderItem={item => <List.Item>{item}</List.Item>}
                        />
                     ),
                     duration: 0
                  })
               } else if (!_.isNil(response.human)) {
                  notification.warning({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: (
                        <List
                           size="small"
                           split={false}
                           dataSource={response.human}
                           renderItem={item => <List.Item>{item}</List.Item>}
                        />
                     ),
                     duration: 0
                  })
               }
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersUpload: endLoading(this.state.loadersUpload, itemId) })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   downloadFileHandler = (itemId, filePath) => {
      this.setState({ loadersDownload: startLoading(this.state.loadersDownload, itemId) })
      return downloadFromS3(filePath, filePath.split('/').pop())
         .then(response => {
            this.setState({ loadersDownload: endLoading(this.state.loadersDownload, itemId) })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersDownload: endLoading(this.state.loadersDownload, itemId) })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   exportExcelHandler = () => {
      let filename
      this.setState({ multipleExportLoader: true });
      filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_PROGRESSIVES.xlsx';
      return storeActions.exportExcel(filename, this.state.selectedRowKeys)
         .then(response => {
            this.setState({ multipleExportLoader: false });
            console.log(response);
            try {
               this.setState({ loader: false });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            this.setState({ multipleExportLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         })
   }

   exportDetailsHandler = (itemIds, is_multiple = false) => {
      let filename
      this.setState({ loadersExportDetails: startLoading(this.state.loadersExportDetails, itemIds) })
      if (is_multiple) {
         this.setState({ multipleExportLoader: true });
      }
      filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_PROGRESSIVE_DETAILS.xlsx';
      return storeActions.exportDetails(filename, itemIds)
         .then(response => {
            this.setState({ loadersExportDetails: endLoading(this.state.loadersExportDetails, itemIds) })
            if (is_multiple) {
               this.setState({ multipleExportLoader: false });
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersExportDetails: endLoading(this.state.loadersExportDetails, itemIds) })
            if (is_multiple) {
               this.setState({ multipleExportLoader: false });
            }
            if (this.props.commonState.debug) console.log(e);
         })
   }

   showNote = (item, show) => {
      // this.setState({ showModalNote: show, modalNoteItem: item })
      Modal.info({
         // style: { width: 500 },
         title: item.progressive,
         content: (
            <div>
               <p>{item.note}</p>
            </div>
         ),
         onOk() { },
      });
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title, Text } = Typography
      const { Panel } = Collapse
      const { Option } = Select
      const table_columns = [
         {
            title: 'Progressivo',
            width: 100,
            key: 'progressive',
            fixed: 'left',
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'progressive' ? this.state.sortedInfo.order : null,
            render: (record) => (
               record.progressive
            )
         },
         {
            title: 'Vendor Code',
            width: 80,
            key: 'vendor_code_id',
            fixed: 'left',
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'vendor_code_id' ? this.state.sortedInfo.order : null,
            render: (record) => (
               !_.isNil(record.vendor_code) ? record.vendor_code.code : null
            )
         },
         {
            title: 'FC Code',
            width: 60,
            key: 'amazon_warehouse.fc_code',
            fixed: 'left',
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'amazon_warehouse.fc_code' ? this.state.sortedInfo.order : null,
            render: (record) => (
               !_.isNil(record.amazon_warehouse) ? record.amazon_warehouse.fc_code : null
            )
         },
         {
            title: 'Tipo',
            width: 60,
            key: 'type',
            fixed: 'left',
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'type' ? this.state.sortedInfo.order : null,
            render: (record) => (
               languageManager.getMessage(this.props.commonState, 'component.progressives.type.' + record.type)
            )
         },
         {
            title: 'Paese',
            width: 60,
            key: 'country_id',
            fixed: 'left',
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'country_id' ? this.state.sortedInfo.order : null,
            render: (record) => (
               record.country.code
            )
         },
         {
            title: 'Gruppo',
            width: 60,
            key: 'group',
            fixed: 'left',
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'group' ? this.state.sortedInfo.order : null,
            render: (record) => (
               languageManager.getMessage(this.props.commonState, 'component.progressives.group.' + record.group)
            )
         },
         // {
         //    title: 'Categoria',
         //    width: 100,
         //    key: 'category',
         //    fixed: 'left',
         //    sorter: true,
         //    render: (record) => (
         //       record.category
         //    )
         // },
         {
            title: 'Fornitore/Magazzino',
            width: 150,
            key: 'supplier_id',
            fixed: 'left',
            render: (record) => (
               !_.isNil(record.supplier) ? record.supplier.name : !_.isNil(record.warehouse) ? record.warehouse.name : null
            ),
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'supplier_id' ? this.state.sortedInfo.order : null,
         },
         {
            title: 'Brand',
            width: 100,
            key: 'brand_id',
            render: (record) => (
               record.brand.name
            ),
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'brand_id' ? this.state.sortedInfo.order : null,
         },
         {
            title: 'Pz richiesti',
            width: 75,
            key: 'requested_quantity',
            render: (record) => (
               record.requested_quantity
            ),
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'requested_quantity' ? this.state.sortedInfo.order : null,
         },
         {
            title: 'Pz confermati',
            width: 75,
            key: 'confirmed_quantity',
            render: (record) => (
               record.confirmed_quantity
            ),
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'confirmed_quantity' ? this.state.sortedInfo.order : null,
         },
         {
            title: 'Eccedenze',
            width: 75,
            key: 'surplus_quantity',
            render: (record) => (
               record.surplus_quantity
            ),
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'surplus_quantity' ? this.state.sortedInfo.order : null,
         },
         {
            title: 'WHLS richiesto',
            width: 100,
            key: 'requested_wsp',
            render: (record) => (
               record.requested_wsp_human
            ),
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'requested_wsp' ? this.state.sortedInfo.order : null,
         },
         // {
         //    title: 'WHLS confermato',
         //    width: 100,
         //    key: 'confirmed_wsp',
         //    render: (record) => (
         //       record.confirmed_wsp_human
         //    ),
         //    sorter: true,
         //    sortOrder: this.state.sortedInfo.columnKey === 'confirmed_wsp' ? this.state.sortedInfo.order : null,
         // },
         // {
         //    title: 'Cliente',
         //    width: 120,
         //    key: 'customer_id',
         //    render: (record) => (
         //       !_.isNil(record.customer) ? record.customer.name : null
         //    ),
         //    sorter: true,
         //    sortOrder: this.state.sortedInfo.columnKey === 'customer_id' ? this.state.sortedInfo.order : null,
         // },
         // {
         //    title: 'Condizioni di acquisto/vendita',
         //    width: 150,
         //    key: 'conditions_buy_sell',
         //    render: (record) => (
         //       <Space direction='vertical' size='small'>
         //          <Text>{record.conditions_buy}</Text>
         //          <Text>{record.conditions_sell}</Text>
         //       </Space>
         //    ),
         // },
         {
            title: 'Inizio finestra',
            width: 80,
            key: 'shipment_window_start',
            render: (record) => (
               <Space direction='vertical' size={0}>
                  <Text>{record.shipment_window_start_date_human}</Text>
                  <Text>{record.shipment_window_start_time_human}</Text>
               </Space>
            ),
            // sorter: (a, b) => a.shipment_window_start.localeCompare(b.shipment_window_start),
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'shipment_window_start' ? this.state.sortedInfo.order : null,
         },
         {
            title: 'Fine finestra',
            width: 80,
            key: 'shipment_window_end',
            render: (record) => (
               <Space direction='vertical' size={0}>
                  <Text>{record.shipment_window_end_date_human}</Text>
                  <Text>{record.shipment_window_end_time_human}</Text>
               </Space>
            ),
            // sorter: (a, b) => a.shipment_window_end.localeCompare(b.shipment_window_end),
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'shipment_window_end' ? this.state.sortedInfo.order : null,
         },
         {
            title: 'Creazione',
            width: 150,
            key: 'created_at',
            render: (record) => (
               <Space direction='vertical' size={0}>
                  <Text>{record.created_at_human}</Text>
                  <Text>{record.user.firstname + ' ' + record.user.lastname}</Text>
               </Space>
            ),
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'created_at' ? this.state.sortedInfo.order : null,
         },
         {
            title: 'Sorgente',
            width: 100,
            key: 'source_id',
            render: (record) => (
               !_.isNil(record.source_id) ? languageManager.getMessage(this.props.commonState, 'component.progressives.source.' + record.source_id) : null
            ),
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'source_id' ? this.state.sortedInfo.order : null,
         },
         {
            title: 'Stato',
            width: 100,
            key: 'status_id',
            className: 'tbold',
            render: (record) => (
               !_.isNil(record.status) ? record.status.name : languageManager.getMessage(this.props.commonState, 'common.status.unknown')
            ),
            sorter: true,
            sortOrder: this.state.sortedInfo.columnKey === 'status_id' ? this.state.sortedInfo.order : null,
         },
         {
            title: 'Note',
            width: 100,
            key: 'note',
            ellipsis: true,
            render: (record) => (
               record.note ? <Text onClick={() => this.showNote(record)}>{record.note}</Text> : null
            ),
         },
         // {
         //    title: 'Ordini associati',
         //    width: 150,
         //    key: 'purchase_orders_list',
         //    render: (record) => (
         //       _.forEach(record.purchase_orders_ids, (orderId, i) => {

         //       })
         //    ),
         // },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            render: (text, record) => (
               <Space>
                  {/* <Button type="success" size='small'
                     icon={<EyeOutlined />}
                     onClick={() => this.showViewDrawer(record)}
                  /> */}
                  {
                     !_.isNil(record.status) && record.status.code !== 'MERGED' && record.status.code !== 'DUPLICATED' ? (
                        <Tooltip title="Modifica" placement="topRight">
                           <Button type="primary" size='small'
                              icon={<EditOutlined />}
                              onClick={() => this.editItemHandler(record)}
                           />
                        </Tooltip>
                     ) : null
                  }
                  {
                     !_.isNil(record.status) && record.status.code === 'WAITING_SUPPLIER' ? (
                        <Tooltip title="Duplica" placement="topRight">
                           <Button size='small' type='warning'
                              icon={<CopyOutlined />}
                              loading={this.state.loadersDuplicate[record.id]}
                              onClick={() => this.duplicateItemsHandler([record.id])}
                           />
                        </Tooltip>
                     ) : null
                  }
                  {
                     !_.isNil(record.status) && record.status.code === 'SUPPLIER_CONFIRMED' ? (
                        <Tooltip title="Duplica pz mancanti" placement="topRight">
                           <Button size='small' type='warning'
                              icon={<CopyOutlined />}
                              loading={this.state.loadersDuplicate[record.id]}
                              onClick={() => this.duplicateItemsHandler([record.id], true)}
                           />
                        </Tooltip>
                     ) : null
                  }
                  {
                     !_.isNil(record.status) && record.status.code === 'WAITING_SUPPLIER' ? (
                        <Tooltip title="Conferma tutto" placement="topRight">
                           <Button type="success" size='small'
                              icon={<CheckOutlined />}
                              loading={this.state.loadersConfirm[record.id]}
                              onClick={() =>
                                 Modal.confirm({
                                    title: languageManager.getMessage(this.props.commonState, 'common.confirm_item'),
                                    icon: <QuestionCircleOutlined />,
                                    content: languageManager.getMessage(this.props.commonState, 'common.askConfirmConfirm'),
                                    okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                    onOk: () => this.confirmItemHandler(record.id)
                                 })
                              }
                           />
                        </Tooltip>
                     ) : null
                  }
                  {
                     !_.isNil(record.status) && record.status.code === 'CANCELLED' ? (
                        <Tooltip title="Ripristina progressivo" placement="topRight">
                           <Button type="success" size='small'
                              icon={<RollbackOutlined />}
                              loading={this.state.loadersRestore[record.id]}
                              onClick={() =>
                                 Modal.confirm({
                                    title: languageManager.getMessage(this.props.commonState, 'common.restore_item'),
                                    icon: <QuestionCircleOutlined />,
                                    content: languageManager.getMessage(this.props.commonState, 'common.askRestoreConfirm'),
                                    okText: languageManager.getMessage(this.props.commonState, 'common.restore'),
                                    onOk: () => this.restoreItemHandler(record.id)
                                 })
                              }
                           />
                        </Tooltip>
                     ) : null
                  }
                  {
                     !_.isNil(record.status) && record.status.code !== 'CANCELLED' && record.status.code !== 'MERGED' && record.status.code !== 'DUPLICATED' ? (
                        <Tooltip title="Annulla tutto" placement="topRight">
                           <Button type="danger" size='small'
                              icon={<CloseOutlined />}
                              loading={this.state.loadersCancel[record.id]}
                              onClick={() =>
                                 Modal.confirm({
                                    title: languageManager.getMessage(this.props.commonState, 'common.cancel_item'),
                                    icon: <QuestionCircleOutlined />,
                                    content: languageManager.getMessage(this.props.commonState, 'common.askCancelConfirm'),
                                    okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                    onOk: () => this.cancelItemHandler(record.id)
                                 })
                              }
                           />
                        </Tooltip>
                     ) : null
                  }
                  {/* {
                     record.type === PurchaseOrderTypeEnum.UNKNOWN ? (
                        <Button type="warning" size='small'
                           icon={<UploadOutlined />}
                           loading={this.state.loadersUpload[record.id]}
                           title={languageManager.getMessage(this.props.commonState, 'common.send_to_amazon')}
                           onClick={() =>
                              Modal.confirm({
                                 title: languageManager.getMessage(this.props.commonState, 'common.send_to_amazon'),
                                 icon: <QuestionCircleOutlined />,
                                 content: languageManager.getMessage(this.props.commonState, 'common.askSendConfirm'),
                                 okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                 onOk: () => this.sendToAmazonHandler(record.id)
                              })
                           }
                        />
                     ) : null
                  } */}
                  {
                     !_.isNil(record.filename_nzt) ? (
                        <Button size='small' title='Esporta file originale'
                           icon={<DownloadOutlined />}
                           loading={this.state.loadersDownload[record.id]}
                           onClick={() => this.downloadFileHandler(record.id, record.filename_nzt)}
                        />
                     ) : null
                  }
                  {
                     !_.isNil(record.filename_nzt) ? (
                        <Button size='small' type='success' title='Esporta dettagli aggiornati'
                           icon={<DownloadOutlined />}
                           loading={this.state.loadersExportDetails[record.id]}
                           onClick={() => this.exportDetailsHandler([record.id])}
                        />
                     ) : null
                  }
               </Space >),
         },
      ];
      const tableLayout = {
         bordered: true,
         // ellipsis: true,
         size: "small",
         showSorterTooltip: false,
         loading: this.state.tableLoader,
         pagination: {
            current: this.state.paginationParams.current_page,
            pageSize: this.state.paginationParams.per_page,
            total: this.state.paginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' progressivi' : ' progressivo')
         },
         onChange: this.handleTableChange,
         rowClassName: (record) => { return !_.isNil(record.status) && (record.status.code === 'CANCELLED' || record.status.code === 'MERGED' || record.status.code === 'DUPLICATED') ? 'record-cancelled' : null }
      }

      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true,
      };

      const progressivesTable = (
         <Table
            {...tableLayout}
            columns={table_columns}
            rowSelection={rowSelection}
            rowKey={(record) => (record.id)}
            dataSource={this.state.itemList}
         // rowClassName={(record, index) => {
         //    record.order_day==='Monday' % 2 === 0 ? 'table-row-light' :  'table-row-dark'
         // }}
         />
      )

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      );

      const drawerEditProgressive = (
         <DrawerEditProgressive
            key='createDrawer'
            visible={this.state.showCreateDrawer}
            item={this.state.createDrawerItem}
            complements={{ ...this.state.complements }}
            onClose={this.hideCreateDrawer}
            onSubmit={(data) => this.handleCreateOrUpdateProgressive(data)} />
      )
      const drawerViewProgressive = (
         <DrawerViewProgressive
            key='viewDrawer'
            visible={this.state.showViewDrawer}
            item={this.state.viewDrawerItem}
            onClose={this.hideViewDrawer} />
      )
      const drawerImportProgressives = (
         <DrawerImportProgressives
            key='importDrawer'
            visible={this.state.showImportDrawer}
            onSubmit={this.updateList}
            onClose={() => this.showImportDrawer(false)} />
      )

      // const apiFeedback = (
      //       <Alert
      //          closable
      //          message="Dettagli importazione"
      //          type="info"
      //          description={<List
      //             size="small"
      //             dataSource={this.state.response.details}
      //             renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
      //          />}
      //          showIcon
      //       />
      // )

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div>
            <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
               <div className="col-sm-6">
                  <div className="d-none d-md-block">
                     <Space>
                        <Button
                           onClick={() => this.selectAllHandler()}
                        >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                        </Button>
                        <span>
                           {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                        </span>
                        <DropdownMenu
                           type='primary'
                           title='Azioni multiple'
                           action={this.multipleActionHandler}
                           values={this.state.multipleActionList}
                           disabled={!hasSelected}
                           loading={this.state.multipleActionLoader}
                        />
                        <DropdownMenu
                           type='primary'
                           title='Esporta'
                           action={this.multipleActionHandler}
                           values={this.state.multipleExportList}
                           disabled={!hasSelected}
                           loading={this.state.multipleExportLoader}
                        />
                     </Space>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="float-right d-none d-md-block">
                     <Space>
                        <Button icon={<RetweetOutlined />} onClick={() => this.getList(this.state.paginationParams, this.state.filtersValues)}>
                           {languageManager.getMessage(this.props.commonState, 'common.refresh_data')}
                        </Button>
                        <Button
                           type='primary'
                           icon={<PlusOutlined />}
                           onClick={() => this.showImportDrawer(true)}
                        >
                           {languageManager.getMessage(this.props.commonState, 'component.progressives.import')}
                        </Button>
                        <Button
                           type='primary'
                           icon={<PlusOutlined />}
                           onClick={() => this.editItemHandler(null)}
                        >
                           {languageManager.getMessage(this.props.commonState, 'component.progressives.new')}
                        </Button>
                     </Space>
                  </div>
               </div>
            </div>
            {progressivesTable}
            {drawerEditProgressive}
            {drawerViewProgressive}
            {drawerImportProgressives}
         </div >
      )

      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.progressives.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row>
               {pageContent}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            {pageWrapper}
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(ProgressivesList);